import React, { useEffect, useRef, useState } from "react";
import Home from "../src/views/home/Home";
import About from "../src/views/about/About";
import Contact from "../src/views/contact/Contact";
import Prices from "../src/views/prices/Prices";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./Auth/Login";
import Signup from "./Auth/Signup";
import ThankYou from "./Auth/ThankYou";
import Orgnaization from "./Auth/Orgnaization";
import Teacher from "./Auth/Teacher";
import Scroll from "./components/cmp/Scroll";
import Forgetpassword from "./Auth/Forgetpassword";
import Dashboard from "./Dashboard/Dashboard";
import Events from "../src/Dashboard/DashboardPages/Events";
import FindBuddy from "../src/Dashboard/DashboardPages/FindBuddy/FindBuddy";
import EventDetails from "./Dashboard/DashboardPages/EventDetails";
import Checkout from "./views/Checkout/CheckoutScreen";
import { Subscription } from "./Dashboard/DashboardPages/Subscription/Subscription";
import Favorites from "./views/MyFavorites/Favorites";
import { IslamicCulture } from "./Dashboard/DashboardPages/Islamic-Culture/IslamicCulture";
import LaunchingPage from "./views/LaunchingPage/LaunchingPage";
import StudentDashboard from "./StudentDashboard/Dashboard";
import OrgDashboard from "./OrganizationDashboard/OrgDashboard";
import { OrgMyEvents } from "./OrganizationDashboard/OrgSidebarContent/OrgMyEvents/OrgMyEvents";
import { Notification } from "./OrganizationDashboard/OrgSidebarContent/Notification/Notification";
import OrganizationDetail from "./OrganizationDashboard/OrgSidebarContent/Organization-Details/OrganizationDetail";
import OrganizationProfile from "./OrganizationDashboard/OrgSidebarContent/organizationprofile/OrganizationProfile";
import OrganizationEditProfile from "./OrganizationDashboard/OrgSidebarContent/organizationeditprofile/OrganizationEditProfile";
import { OrgReviewsFeedback } from "./OrganizationDashboard/OrgSidebarContent/OrgReviewsAndFeedback/OrgReviewsFeedback";
import FindBuddyDetails from "./Dashboard/DashboardPages/FindBuddydetails/FindBuddyDetails";
import CommingSoon from "./components/CommingSoon/ComingSoon";
import CheckMailConfirmation from "./Auth/CheckMailConfirmation";
import ResetPassword from "./Auth/ResetPassword";
import ContactThankYou from "./Auth/ContactThankYou";
import { setModalData, setSockets, setTyping, setUsers } from "./Store/Actions";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import Page404 from "./views/nointernet/Page404";
import PrivatePolicy from "./layout/PrivatePolicy";
import TermsOfService from "./layout/TermsOfService";
import HelpCenter from "./layout/HelpCenter";
import CustomModal from "./components/reuseable/model";
import { StuNotifications } from "./StudentDashboard/Notification/StuNotifications";
import { getFirebaseToken, messagingInstance } from "../src/firebase";
import {
  getToken,
  getMessaging,
  isSupported,
  onMessage,
} from "firebase/messaging";
import { showMessage } from "./components/reuseable/Tostify";
import ring from "./asserts/images/vido/ring.mp3";
import image from "./asserts/images/nointernet404/NoInternetAvaliable.png";
import DonationThankYou from "./Auth/DonationThankYou";
import Launchinghome from "./views/home/Launchinghome";
import Resubscribtion from "./components/Re-subscribtion/Resubscribtion";
import { clearAllCookies, getCookies } from "./lib/auth";
const App = () => {
  const [user, setuser] = useState(secureLocalStorage.getItem("id"));
  const [role, setRole] = useState();
  const [socketConnected, setSocketConnected] = useState();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const dispatch = useDispatch();
  const socket = useSelector((state) => state.sockets);
  let modalData = useSelector((state) => state.modalData);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  useEffect(() => {
    // dispatch(
    //   setSockets(
    //     io("https://ilmcircle.be", {
    //       path: "/backend",
    //     })
    //   )
    // );
    let newSocket; 
    try {
      dispatch(setSockets(io("https://apis.ilmcircle.com")));
      // newSocket = io("http://localhost:8080");
      dispatch(setSockets(newSocket));
      dispatch(setSockets(null)); 
      console.log("Socket.IO setup successfully", socket);
    } catch (error) {
      console.log("Error setting up Socket.IO:", error);
    }
    return () => {
      newSocket.disconnect()
      dispatch(setSockets(null)); // Clear stale socket from Redux
    };
    // console.log(socket)
    // dispatch(setSockets(io("https://apis.ilmcircle.com")));
  }, []);

  const navigate = useNavigate();  
  useEffect(() => {
    if(socket) {
      try {
        socket.on("connect", () => {
        setSocketConnected(true)
        socket?.emit("addUser", user);
        socket?.on("getUsers", (users) => {
          dispatch(setUsers(users));
        });
      });

      socket.on("disconnect", () => {
        setSocketConnected(false)
      });

      let typingTimeout = null;
      socket?.on("typing", (data) => {
        dispatch(setTyping(data));
        if (typingTimeout) {
          clearTimeout(typingTimeout);
        }
        typingTimeout = setTimeout(() => {
          dispatch(setTyping({}));
        }, 3000);
      });
    } catch (error) {
      console.log(error);
    }  }
  }, [socket]);

  useEffect(() => {
    const accessToken = getCookies("token");
    if (!accessToken) {
      clearAllCookies();
      secureLocalStorage.clear();
      // showMessage('Your session has expired. please login again!', "error");
      // return  navigate('/login');
    }
    const checkToken = async () => {
      const token = await getFirebaseToken();
      try {
        if (accessToken || token) {
          verifyNotificationToken(token);
        }
      } catch (err) {
        console.log("An error occurred while retrieving token. ", err);
      }
    };
    

    const verifyNotificationToken = async (token) => {
      // console.log('generatedToken===>', generateToken)
      var myHeaders = new Headers();

      myHeaders.append("Authorization", "Bearer " + accessToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        "https://apis.ilmcircle.com/api/user/token/" + token,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 200 || result.status == 201) {
          } else {
    
            // clearAllCookies();
            // secureLocalStorage.clear();
            // showMessage('Your session has expired. please login again!', "error");
          }
        })
        .catch((error) => {
          
          // clearAllCookies();
          // secureLocalStorage.clear();
          // console.log("error", error);
        });
    };

    try {
      const handleForegroundMessage = async (payload) => {
        // const {
        //   notification: { title, body },
        //   data,
        // } = payload;
        if (payload?.data?.title === "Incoming Call") {
          handleIncomingCallNotification({ title: payload?.data?.title, body: payload, data: payload.data});
        }
        if (payload?.data?.title.startsWith("Message:")) {
          console.log("message")
          const dataJson = JSON.parse(payload.data.data)
          const senderId = JSON.parse(dataJson.userId)
          if(id != senderId){
            showMessage(payload.data.title.replace("Message: ", ""), "")
          }
        }
      };
      onMessage(messagingInstance, handleForegroundMessage);
    } catch (error) {
      console.log(error);
    }
    checkToken();
  }, [id]);

  // navigator.serviceWorker.getRegistrations().then((registrations) => {
  //   console.log("Service worker registrations:", registrations);
  // });

  // useEffect(() => {
  //   if (!token && !allowedRoutes.includes(location.pathname)) {
  //     var myHeaders = new Headers();
  //     myHeaders.append("Authorization", "Bearer " + token);
  //     var requestOptions = {
  //       method: "GET",
  //       headers: myHeaders,
  //       redirect: "follow",
  //     };

  //     fetch(
  //       "https://apis.ilmcircle.com/api/student/allevents",
  //       requestOptions
  //     )
  //       .then((response) => response.json())
  //       .then((result) => {
  //         if (result?.status === 401) {
  //           setStatusMessage(result.message);
  //           openModal(true);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("error", error);
  //       });
  //   }
  // }, [location.pathname, token]);
  const handleIncomingCallNotification = (notification) => {
    const { title, body } = notification;

    if (title === "Incoming Call") {
      dispatch(setModalData({ body: JSON.parse(body.data.body), showModal: true, isModal: true }));
      const gcmData = notification.data;
      if (gcmData?.path == "/student-dashboard/5") {
        setRole("student");
      } else {
        setRole("organization");
      }

      navigate(gcmData?.path);
    }
  };

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      showMessage("Welcome back! You are now online.");
    };
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  if (!isOnline) {
    return (
      <div>
        <div className="container">
          <div className="row ">
            <div className="col-12 d-flex justify-content-center">
              <img
                style={{ height: "100%", width: "50%", objectFit: "cover" }}
                src={image}
                alt="No InterNet"
              />
            </div>
          </div>
          <div className="row align-items-center">
            <h2 className="d-flex justify-content-center">No Internet </h2>
            <h2 className="d-flex justify-content-center text-center">
              Check your connection and try again!{" "}
            </h2>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* <div>
        {isModalOpen && (
          <CustomModal title={statusMessage} onClose={closeModal} />
        )}
      </div> */}
      {modalData?.showModal && (
        <div>
          <audio
            style={{ display: "none" }}
            controls
            autoPlay
            onPlay={() => console.log("Audio is playing")}
            onPause={() => console.log("Audio is paused")}
          >
            <source src={ring} type="audio/mpeg" />
          </audio>
        </div>
      )}

      <Scroll />
      <Routes>
        {/* <Route  path='/' element={<Home />} /> */}
        <Route path="/" element={<Launchinghome />} />
        <Route path="/lauching-page" element={<LaunchingPage />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/prices" element={<Prices />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/my-favorites" element={<Favorites />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/page" element={<ThankYou />} />
        <Route path="/thank-you" element={<ContactThankYou />} />
        <Route path="/thank-you-donate" element={<DonationThankYou />} />

        <Route path="/orgnaization" element={<Orgnaization />} />
        <Route path="/teacher" element={<Teacher />} />
        <Route path="/forget-password" element={<Forgetpassword />} />
        <Route path="/confirm-email" element={<CheckMailConfirmation />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/events" element={<Events />} />
        <Route path="/islamic-culture/:id" element={<IslamicCulture />} />
        <Route path="/events-details/:id/:userId" element={<EventDetails />} />
        <Route
          path="/student-dashboard/:index"
          element={<StudentDashboard />}
        />
        <Route path="/organization-dashboard/:id" element={<OrgDashboard />} />
        <Route path="/organization-events" element={<OrgMyEvents />} />
        <Route path="/find-buddy-details/:id" element={<FindBuddyDetails />} />
        <Route path="/private-policy" element={<PrivatePolicy />} />
        <Route path="/terms-of-services" element={<TermsOfService />} />
        <Route path="/help-center" element={<HelpCenter />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/student-notifications" element={<StuNotifications />} />

        <Route path="/coming-soon" element={<CommingSoon />} />

        <Route path="/reviews-feedback" element={<OrgReviewsFeedback />} />

        {/* <Route path='/find-teacher' element={<FindTeacher/>} />
        <Route path='/teacher-details/:id' element={<TeacherDetails/>} /> */}

        <Route path="/find-buddy" element={<FindBuddy />} />
        <Route path="/organization-details" element={<OrganizationDetail />} />
        <Route exact path="/Myprofile" element={<OrganizationProfile />} />
        <Route
          exact
          path="/EditProfile"
          element={<OrganizationEditProfile />}
        />

        <Route path="/re-subscribe" element={<Resubscribtion />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </>
  );
};

export default App;
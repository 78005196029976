import React, { useState } from "react";
import "./DashboardMain/DashboardMain.css";
import { HeaderSection } from "./DashboardMain/HeaderSection";
import { listData } from "./DummyData/EventandClass";
import secureLocalStorage from "react-secure-storage";
import Slider from "react-slick";
import reverseIcon from "../../asserts/images/reverseIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import myEventCloseIcon from "../../asserts/images/myEventCloseIcon.png";
import editIcon from "../../asserts/images/orgEditIcon.png";
import { setAyat, setGoalTaskData } from "../../Store/Actions";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../Store/Index";
import { showMessage } from "../../components/reuseable/Tostify";
import API_Routes from "../../Routes/API_Routes";
import axios from "axios";
import { EventsClass } from "./EventClass/EventsClass";
import { EventsSeprate } from "./EventClass/EventsSeprate";
import { useNavigate } from "react-router-dom";
import Modal from "./Goals/Modal";
import { getCookies } from "../../lib/auth";
// import { Modal } from "../";
const MyDashboard = () => {
  const dispatch = useDispatch();
  const [onlineUsers, setOnlineUsers] = useState("");
  const [onlineBuddy, setOnlineBuddy] = useState(false);
  const users = useSelector((state) => state.users);
  const [loader, setLoader] = useState(false);
  const user = secureLocalStorage.getItem("id");
  const [isPlaying, setIsPlaying] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const getAyat = useSelector((state) => state.Ayat);
  const [memorizationTip, setMemorizationTip] = useState([]);
  const [uniqueuser, setuniqueusers] = useState(false);
  const { studDashboardIndex } = bindActionCreators(actionCreaters, dispatch);
  const token = getCookies("token");
  const getOnlineUsers = () => {
    let userIds = users.map((item) => item.userId).filter((item) => item); // Ensure no empty values

    if (userIds.length === 0) {
      console.warn("No user IDs available");
      return;
    }

    const token = getCookies("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ ids: userIds });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://apis.ilmcircle.com/api/student/online", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setOnlineUsers(result?.data || []);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (!token) {
      navigate("/login");
      return;
    }
    getOnlineUsers();
    const intervalId = setInterval(() => {
      getOnlineUsers();
    }, 10000);

    AyatData();
    return () => clearInterval(intervalId);

  }, [token, navigate]);
  const ShowDrop = (e) => {
    secureLocalStorage.setItem("slider", e.target.value);
    setIsPlaying(e.target.value === "Play");
  };
  const AyatData = () => {
    setLoader(true);
    const axiosConfig = {
      method: "get",
      url: "https://apis.ilmcircle.com/api/page/ayat",
    };

    axios(axiosConfig)
      .then((response) => {
        if (response.status == 200 || response.status == 201) {
          setLoader(false);
          dispatch(setAyat(response?.data?.data));
        } else {
          setLoader(false);
          showMessage(response?.data?.message, "error");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoader(false);
      });
  };

  const MemorizationTips = () => {
    setLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(API_Routes.GETMEMORIZATIONTIPS, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 200 || result.status == 201) {
          setMemorizationTip(result?.data);
          setLoader(false);
        } else {
          setLoader(false);
          showMessage(result.message, "error");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoader(false);
      });
  };
  const sliderSettings = {
    dots: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const sliderSettings2 = {
    dots: false,
    autoplay: false,
  };
  const sliderSettingsMemo = {
    dots: false,
    autoplay: true,
  };
  const handleItemClick = (index) => {
    navigate(`/student-dashboard/${index}`);
  };
  const [containerHeight, setContainerHeight] = useState(400);
  const [biggerDivHeight, setBiggerDivHeight] = useState(0);
  const [smallerDivHeight, setSmallerDivHeight] = useState(0);
  const [extrasmallerDivHeight, setSExtramallerDivHeight] = useState(0);
  useEffect(() => {
    const updateContainerHeight = () => {
      const clientHeight = window.innerHeight;

      const ratioBiggerDiv = 0.5;
      const ratioSmallerDiv = 0.25;
      let calculatedSmallerDivHeight;
      let calculatedBiggerDivHeight;

      if (clientHeight < 900) {
        calculatedSmallerDivHeight = clientHeight * ratioSmallerDiv - 44;
        calculatedBiggerDivHeight = clientHeight * ratioBiggerDiv - 20;
        setSExtramallerDivHeight(calculatedBiggerDivHeight / 2 - 4);
      } else {
        calculatedSmallerDivHeight = clientHeight * ratioSmallerDiv;
        calculatedBiggerDivHeight = clientHeight * ratioBiggerDiv - 16;
        setSExtramallerDivHeight(calculatedBiggerDivHeight / 2 - 4);
      }

      if (calculatedBiggerDivHeight <= 343) {
        setBiggerDivHeight(343);
      } else {
        setBiggerDivHeight(calculatedBiggerDivHeight);
      }

      if (calculatedSmallerDivHeight <= 140) {
        setSmallerDivHeight(140);
      } else {
        setSmallerDivHeight(calculatedSmallerDivHeight);
      }
    };

    // Initial update
    updateContainerHeight();

    // Event listener for window resize
    window.addEventListener("resize", updateContainerHeight);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateContainerHeight);
    };
  }, []);

  const getStyle = () => {
    const isMobile = window.innerWidth <= 768;
    return {
      height: isMobile ? "auto" : biggerDivHeight,
    };
  };
  const checkId = () => {
    let value = onlineUsers.some((userid) => userid._id === user);
    return value;
  };

  return (
    <div className=" studentDashboardMain2">
      {/* <Modal/> */}
      <Modal />
      <div
        style={{
          backgroundColor: "#F3FAFC",
          width: "100%",
          zIndex: -1,
          height: window.innerHeight,
        }}
        className="background-color-div position-absolute top-0 left-0 right-0 bottom-0 h-100"
      ></div>
      <div className=" studentDashboardMain" style={{ paddingLeft: "126px" }}>
        <div className="row ">
          <div className="col-xl-9">
            <HeaderSection divHeight={biggerDivHeight} />
          </div>
          <div className="col-xl-3">
            {/* <div className="MainSideBarList margin-dashboard-calender" style={{ minHeight: "100%" }}>
              <h6 className=" pb-2">Online study buddies</h6>
              {onlineUsers?.length ? (
                onlineUsers.map(
                  (item, index) =>
                    item?.userDetailId &&
                    item?._id != user && (
                      <div className="smallCard" key={index}>
                        <div className="position-relative">
                          <img src={item?.userDetailId?.image?.url} alt="" />
                          <span className="smallCardDot"></span>
                        </div>
                        <div className="ms-2">
                          <h5 className="listName mb-0">
                            {item?.userDetailId?.firstName +
                              item?.userDetailId?.lastName}
                          </h5>
                          <p className="listDate">
                            {new Date().toLocaleTimeString()}
                          </p>
                        </div>
                      </div>
                    )
                )
              ) : (
                <div className="text-center" style={{ color: "rgb(244, 123, 0" }}>
                  No one is online
                </div>
              )}
            </div> */}
            <div style={getStyle()} className="">
              <div
                style={{ height: "47%" }}
                className="centertedMainDiv position-relative margin-dashboard-calender mt-3 mt-xl-0"
              >
                <div className="centerIconContainer d-flex align-items-center gap-2 justify-content-between text-end p-1">
                  {showDropdown ? (
                    <img
                      className="mx-2 pointer"
                      style={{ width: 16.67, height: 16.67 }}
                      src={myEventCloseIcon}
                      alt="editIcon"
                      onClick={() => setShowDropdown(false)}
                    />
                  ) : (
                    <img
                      className="mx-2 pointer"
                      style={{ width: 16.67, height: 16.67 }}
                      src={editIcon}
                      alt="editIcon"
                      onClick={() => setShowDropdown(true)}
                    />
                  )}

                  {showDropdown && (
                    <div
                      className="custom-select position-absolute"
                      style={{
                        width: 200,
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      <select
                        className="form-select custom-select"
                        onChange={(e) => ShowDrop(e)}
                        value={isPlaying ? "Play" : "Pause"}
                      >
                        <option selected disabled>
                          Select
                        </option>
                        <option value="Play">Play</option>
                        <option value="Pause">Pause</option>
                      </select>
                    </div>
                  )}

                  <img
                    className="ms-2 me-4 pointer"
                    style={{ width: 14.58, height: 17.2 }}
                    src={reverseIcon}
                    alt="reverseIcon"
                    onClick={() => AyatData()}
                  />
                </div>

                {isPlaying ? (
                  <>
                    {/* {alert('slide')} */}
                    <Slider arrows={false} autoplay {...sliderSettings}>
                      {getAyat.map((item) => (
                        <div key={item._id}>
                          <div className="h3">"{item.ayat}"</div>
                          <div className="pText">({item.detail})</div>
                        </div>
                      ))}
                    </Slider>
                  </>
                ) : (
                  <Slider arrows={false} {...sliderSettings2}>
                    {getAyat.map((item) => (
                      <div key={item._id}>
                        <div className="h3">"{item.ayat}"</div>
                        <div className="pText">({item.detail})</div>
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
              <div
                style={{ height: "47%" }}
                className="memorizingContainer margin-dashboard-calender"
              >
                <div className="d-flex justify-content-between">
                  <h3 className="memorizingHeader">Memorizing tips</h3>
                  <img
                    className="ms-2 me-4 pointer"
                    style={{ width: 14.58, height: 17.2 }}
                    src={reverseIcon}
                    alt="reverseIcon"
                    onClick={MemorizationTips}
                  />
                </div>
                <div className="textContainer">
                  <Slider arrows={false} autoplay {...sliderSettingsMemo}>
                    {memorizationTip?.map((item) => (
                      <div key={item._id}>
                        <p className="mb-0">{item?.description}</p>
                      </div>
                    ))}
                  </Slider>
                  {/* <p className="mb-0">
                Stay organized: Keep your study materials and notes organized,
                so you can easily access and review them when needed.
              </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row position-relative">
          <div className="col-lg-6">
            <div className="margin-dashboard-calender">
              <EventsClass
                divHeight={smallerDivHeight}
                handleItem={handleItemClick}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="margin-dashboard-calender">
              <EventsSeprate
                divHeight={smallerDivHeight}
                handleItem={handleItemClick}
              />
            </div>
          </div>

          <div
            onClick={() => setOnlineBuddy(!onlineBuddy)}
            style={{ cursor: "pointer" }}
            className={`${
              onlineBuddy ? "studybody-elivate" : "online-buddy-bottom"
            } px-0`}
          >
            {/* <div className="studybody-elivate"> */}
            <div className="MainSideBarList margin-dashboard-calender">
              <div className="d-flex align-items-center pb-2 justify-content-between ">
                <h6 className=" ">
                  Online study buddies{" "}
                  <span>
                    (
                    {onlineUsers?.length
                      ? checkId()
                        ? onlineUsers.length - 1
                        : onlineUsers?.length
                      : 0}
                    )
                  </span>
                </h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="9"
                  className={`${onlineBuddy ? "rotate-180" : ""}`}
                  viewBox="0 0 14 9"
                  fill="none"
                >
                  <path
                    d="M2 7L6.41075 2.58925C6.73618 2.26382 7.26382 2.26382 7.58926 2.58925L12 7"
                    stroke="#F47B00"
                    stroke-width="3"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              {onlineUsers?.length ? (
                onlineUsers.map(
                  (item, index) =>
                    item?.userDetailId &&
                    item?._id != user && (
                      <div className="smallCard" key={index}>
                        <div className="position-relative">
                          <img src={item?.userDetailId?.image?.url} alt="" />
                          <span className="smallCardDot"></span>
                        </div>
                        <div className="ms-2">
                          <h5 className="listName mb-0">
                            {item?.userDetailId?.firstName +
                              item?.userDetailId?.lastName}
                          </h5>
                          <p className="listDate">
                            {new Date().toLocaleTimeString()}
                          </p>
                        </div>
                      </div>
                    )
                )
              ) : (
                <div
                  className="text-center pt-5"
                  style={{ color: "rgb(244, 123, 0" }}
                >
                  No one is online
                </div>
              )}
            </div>
          </div>
        </div>

        {/* </div> */}
      </div>
    </div>
  );
};

export default MyDashboard;

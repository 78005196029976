import React, { useState } from "react";
import "../home/home.css";
import { Element, scroller } from "react-scroll";
// ---------------------constant---------------------//
import { sectionOne } from "../../constant/Constant";

// ---------------------components---------------------//
import Header from "../../layout/Header";
import Button from "../../components/reuseable/Button";
import LeftSection from "../../components/reuseable/LeftSection";
import RightSection from "../../components/reuseable/RightSection";
import Testimonel from "../../components/sliders/Testimonel";
import Footer from "../../layout/Footer";

// ---------------------librarys---------------------//
import { ReactSVG } from "react-svg";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// ---------------------Images---------------------//
import myimg from "../../asserts/images/homeOurMission.png";
import left from "../../asserts/images/left.svg";
import right from "../../asserts/images/right.svg";
import leftqoutes from "../../asserts/images/left quote.svg";
import rightqoutes from "../../asserts/images/right quote.svg";
import RightOurMissionSection from "../../components/reuseable/RightOurMissionSection";
import PeopleSection from "../../components/reuseable/PeopleSection";
import { useEffect } from "react";
import { Loader } from "../../components/reuseable/Loader";
import { showMessage } from "../../components/reuseable/Tostify";
import API_Routes from "../../Routes/API_Routes";
import axios from "axios";
import LauchingHeader from "../LaunchingPage/LaucingHeader";
import LaunchingHomeheader from "../../layout/LaunchingHomeheader";
import InputField from "../../components/reuseable/InputField";

import cap from "../../asserts/images/cap.svg";
import ramdan from "../../asserts/images/ramadan 2.svg";
import { addSubscriber } from "../../components/FloDesk/API's";
import hijab3 from "../../asserts/images/hijab3.svg";
import LaunchingHomeFooter from "../../layout/LaunchingHomeFooter";

const Launchinghome = () => {
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const scrollToSection = (sectionId) => {
    scroller.scrollTo(sectionId, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  const [homeData, setHomeData] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [teacherData, setTeacherData] = useState([]);
  const [ourMission, setOutMission] = useState([]);
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [testimonialsheading, setTestimonialsheading] = useState([]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    GetHomeData();
  }, []);

  const GetHomeData = () => {
    setLoader(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("https://apis.ilmcircle.com/api/page/home", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 200 || result.status == 201) {
          setLoader(false);
          const bannerData = result?.data?.filter(
            (item) => item?.key == "banner"
          );
          setBannerData(bannerData);

          const stud_Data = result?.data?.filter(
            (item) => item?.key == "studentData"
          );
          setStudentData(stud_Data);

          const org_Data = result?.data?.filter(
            (item) => item?.key == "organizationData"
          );
          setOrgData(org_Data);

          const teacherData = result?.data?.filter(
            (item) => item?.key == "teacherData"
          );
          setTeacherData(teacherData);
          const missionData = result?.data?.filter(
            (item) => item?.key == "missionSection"
          );
          setOutMission(missionData);

          const testimo_Data = result?.data?.filter(
            (item) => item?.key == "customerData"
          );
          setTestimonialsData(testimo_Data);

          const testimo_header = result?.data?.filter(
            (item) => item?.key == "customerHeader"
          );
          setTestimonialsheading(testimo_header);
        } else {
          setLoader(false);
          showMessage(result?.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  };

  const FetchAboutUSData = () => {
    setLoader(true);
    const formdata = new FormData();

    const axiosConfig = {
      method: "get",
      url: API_Routes.ABOUTUS,
    };

    axios(axiosConfig)
      .then((response) => {
        if (response.status == 200) {
          setData(response.data.data[0]);
          setLoader(false);
        } else {
          showMessage(response?.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoader(false);
      });
  };

  useEffect(() => {
    FetchAboutUSData();
  }, []);

  const SendNotifyMeData = () => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      showMessage("Please enter a valid email address", "error");
      return;
    }

    setLoader(true);

    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("role", selectedOption);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(API_Routes.NOTIFYMEHOME, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200 || result.status === 201) {
          setLoader(false);
          showMessage(result?.message);
          setName("");
          setEmail("");
          setSelectedOption("");
          setIsModalVisible(false);
          addSubscriber(
            {
              email: email,
              first_name: name,
            },
            true
          );
        } else {
          setLoader(false);
          showMessage(result?.message, "error");
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  };

  const options = [
    { value: "student", label: "A student", img: cap },
    { value: "teacher", label: "A teacher", img: hijab3 },
    { value: "organization", label: "An organization", img: ramdan },
  ];

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      {loader && (
        <div className="loaderScreen">
          <Loader />
        </div>
      )}

      <section className="bg-img">
        <main className="container  gx-5">
          <LaunchingHomeheader />

          <div className="row  align-items-center main-hero">
            <div className="col-md-3 box-1">
              {/* <ReactSVG className="img-fluid" src={left} /> */}
              {bannerData[0]?.banner?.leftImage?.url && (
                <img
                  style={{ width: 276, height: 280 }}
                  className="img-fluid"
                  src={bannerData[0]?.banner?.leftImage?.url}
                  alt="img1"
                />
              )}
            </div>
            <div className="col-md-6 headercenteredText">
              <div className="content homecontent text-center">
                <h6 className="home-heading">{bannerData[0]?.banner?.title}</h6>

                <p className="mt-4">{bannerData[0]?.banner?.description}</p>
                <div
                  style={{
                    marginTop: 48,
                  }}
                >
                  <button
                    style={{ borderRadius: 7 }}
                    className="main-btn-home3 w-100 mx-0"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Notify me when it’s available
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-3 box-3">
              {bannerData[0]?.banner?.rightImage?.url && (
                <img
                  style={{ width: 276, height: 280 }}
                  className="img-fluid"
                  src={bannerData[0]?.banner?.rightImage?.url}
                  alt="img1"
                />
              )}
            </div>
          </div>
        </main>
      </section>
      {/* ///////// heroSection end ////////// */}

      {/* /////////Section one///////// */}
      {/* fluid ok  */}
      <Element className="container container-lrg" id="stud">
        <LeftSection
          key2={studentData[0]?.key}
          head={studentData[0]?.studentData?.icon?.url}
          title={studentData[0]?.studentData?.title}
          para={studentData[0]?.studentData?.description}
          btnTitle={studentData[0]?.studentData?.btnText}
          img={studentData[0]?.studentData?.image?.url}
          btnLink={studentData[0]?.studentData?.btnLink}
          route="/"
          goto=""
        />
      </Element>

      {/* ////////section two ////// */}
      {/* fluid ok  */}
      <section className=" bg-right-color image-margin">
        <section className="container container-lrg">
          <RightSection data={orgData} />
        </section>
      </section>

      {/* ///////////section three ////// */}
      {/* fluid ok  */}
      <section className="container container-lrg margin-top-left-right">
        <LeftSection
          key2="teacher"
          head={teacherData[0]?.teacherData?.icon?.url}
          title={teacherData[0]?.teacherData?.title}
          para={teacherData[0]?.teacherData?.description}
          btnTitle={teacherData[0]?.teacherData?.btnText}
          img={teacherData[0]?.teacherData?.image?.url}
          btnLink=""
          route=""
          goto="/coming-soon"
        />
      </section>

      {/* ///////// section four //////// */}
      {/* fluid ok  */}
      <section className="container container-lrg image-margin  margin-top-left-right">
        <RightOurMissionSection data={ourMission} />
      </section>
      <div></div>

      {/* ////////////section four testimonal /////////// */}

      {/* ////////////section five /////////// */}

      <section className="container  ">
        <section>
          <div className="container ">
            <div className="row people-mainHome margin-170">
              <div className="bg-pinkHome"></div>
              <div className="people-box ">
                <div className="d-flex justify-content-center">
                  <h6 style={{ width: 470 }}>{data?.title}</h6>
                </div>
                <button
                  style={{ borderRadius: 7 }}
                  className="profile-btn people-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Keep me notified!
                </button>
                {/* <Button
                  data={"Keep me notified!"}
                  class={"profile-btn people-btn"}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                </Button> */}
              </div>
            </div>
          </div>
        </section>
      </section>

      {/* <!-- Modal --> */}
      <div
        className={`modal fade NotifyModalStyle ${
          isModalVisible ? "show" : ""
        }`}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!isModalVisible}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div class="modal-dialog">
          <div class="modal-content w-100 modal-backgorund">
            <div class="modal-header border-0">
              <h1 class="modal-title fs-5" id="exampleModalLabel"></h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <h6 className="text-center fs-3 mb-3">
                Notify me when it’s available
              </h6>
              <div className="slide-box-one px-5">
                <form onSubmit={SendNotifyMeData}>
                  <div className="mb form-main w-100">
                    <label htmlFor="fname">Name</label>
                    <InputField
                      placeholder={"Input your name "}
                      type="text"
                      value={name}
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="mb form-main w-100">
                    <label htmlFor="fname">E-mail</label>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      placeholder={"Input e-mail address"}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </form>

                <div className="slide-box-body">
                  <div>
                    <h3>I am...</h3>
                  </div>
                  {options.map((option) => (
                    <label key={option.value} className="bottom-slide-box mt-1">
                      <div className="svg-style">
                        <span>
                          <ReactSVG src={option.img} />
                        </span>
                        <span> {option.label}</span>
                      </div>
                      <div>
                        <input
                          type="radio"
                          value={option.value}
                          checked={selectedOption === option.value}
                          onChange={handleOptionChange}
                        />
                      </div>
                    </label>
                  ))}
                  <div>
                    <Button
                      onClick={() => SendNotifyMeData()}
                      data={"Send e-mail"}
                      class={"bottom-btn profile-btn w-100"}
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ////////////////footer ////////////////// */}
      <LaunchingHomeFooter />
    </>
  );
};

export default Launchinghome;

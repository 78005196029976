// import firebase from 'firebase/app'
// import 'firebase/auth'
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// const app = firebase.initializeApp({
//     apiKey: "AIzaSyB1JrSJaUMP5VtLfZJFy9qBSiZUA7v08Kg",
//     authDomain: "ilm-circle-2ab93.firebaseapp.com",
//     projectId: "ilm-circle-2ab93",
//     storageBucket: "ilm-circle-2ab93.appspot.com",
//     messagingSenderId: "50137630524",
//     appId: "1:50137630524:web:4c0f46b29bc0e1dd2ec039",
//     measurementId: "G-JDBH9BSJXT"
// })

// export const auth = firebase.auth();
// const googleprovider = new firebase.auth.GoogleAuthProvider()

// export const sinInWithGoogle = () => {
//     auth.sinInWithGoogle(googleprovider).then((res) => {
//         console.log(res.user)
//     }).catch((error) => {
//         console.log(error.message)
//     })
// }

import { initializeApp } from "firebase/app"; 
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getToken, getMessaging, isSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB1JrSJaUMP5VtLfZJFy9qBSiZUA7v08Kg",
  authDomain: "ilm-circle-2ab93.firebaseapp.com",
  projectId: "ilm-circle-2ab93",
  storageBucket: "ilm-circle-2ab93.appspot.com",
  messagingSenderId: "50137630524",
  appId: "1:50137630524:web:4c0f46b29bc0e1dd2ec039",
  measurementId: "G-JDBH9BSJXT"
// apiKey: "AIzaSyAVNwTzZH3Q2U8jRmAx4IOiv-o8FhXO0wg",
// authDomain: "ilmmm-a5b02.firebaseapp.com",
// projectId: "ilmmm-a5b02",
// storageBucket: "ilmmm-a5b02.firebasestorage.app",
// messagingSenderId: "111473210601",
// appId: "1:111473210601:web:1de9e4e20f1b5f518eec78"
};

const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const provider = new GoogleAuthProvider();

const InitMessaging = async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    return null;
  } catch (err) {
    console.error("Error initializing messaging:", err);
    return null;
  }
};

// const getOrRegisterServiceWorker = async () => {
//   if ("serviceWorker" in navigator) {
//     const existingWorker = await navigator.serviceWorker.getRegistration("/firebase-push-notification-scope");
//     return existingWorker || navigator.serviceWorker.register("/firebase-messaging-sw.js", {
//       scope: "/firebase-push-notification-scope",
//     });
//   }
//   throw new Error("This browser does not support service workers.");
// };

// const getOrRegisterServiceWorker = async () => {
//   if ("serviceWorker" in navigator) {
//     const existingWorker = await navigator.serviceWorker.getRegistration("/firebase-push-notification-scope");
//     if (existingWorker) {
//       return existingWorker;
//     }
//     return navigator.serviceWorker.register("/firebase-messaging-sw.js", {
//       scope: "/firebase-push-notification-scope",
//     });
//   }
//   throw new Error("This browser does not support service workers.");
// };

const unregisterServiceWorkers = async () => {
  if ("serviceWorker" in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (const registration of registrations) {
      await registration.unregister();
    }
    console.log("All service workers unregistered");
  }
};



const getOrRegisterServiceWorker = async () => {
  // await unregisterServiceWorkers()

  if ("serviceWorker" in navigator) {
    try {
      const existingWorker = await navigator.serviceWorker.getRegistration("/firebase-push-notification-scope");

      if (existingWorker) {
        return existingWorker;
      }
      const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js", {
        scope: "/firebase-push-notification-scope",
      });

      return registration;
    } catch (error) {
      console.error("Error registering service worker:", error);
      throw error;
    }
  }
  throw new Error("This browser does not support service workers.");
};


await getOrRegisterServiceWorker();
let messagingInstance = await InitMessaging() 
const getFirebaseToken = async () => {
  if (!messagingInstance) {
    return null;
  }
  
  // unregisterServiceWorkers()

  try {
    const token = await getToken(messagingInstance, { vapidKey: "BKwyzEjhywNnGJ6jEPJ4-sCA2RJHhRNdgUkFh89zqK90-9ibARLeNLL2DnW0nLmwCToiRUWuFOS4s-EA9ISW_Yw" });
    return token
    // return await getToken(messagingInstance, { vapidKey: "BFKzyDhEPLbrG1IWZF3tyzQ-9kxd6y0odQTZ9fxOmofSfLa_Bu2dbI9zSg_VrNLPZ0Lxr1tbjmfjHd7lnU02JWI	" });
  } catch (error) {
    console.error("Error retrieving FCM token:", error);
    return null;
  }
};

export {  getFirebaseToken, messagingInstance };

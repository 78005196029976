import React, { useRef } from "react";
import "./IslamicCulture.css";
import img from "../../../asserts/images/Rectangle.png";
import download from "../../../asserts/images/download.svg";
import heart from "../../../asserts/images/downhert.svg";
import { BsPerson } from "react-icons/bs";
import { FiLink2 } from "react-icons/fi";
import { HiOutlineUsers, HiOutlineLocationMarker } from "react-icons/hi";
import { ReactSVG } from "react-svg";
import DashboardNavbar from "../../DashboardCmp/DashboardNavbar";
import { NavsTabs } from "./NavsTabs";
import Button from "../../../components/reuseable/Button";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Loader } from "../../../components/reuseable/Loader";
import secureLocalStorage from "react-secure-storage";
import API_Routes, { url } from "../../../Routes/API_Routes";
import { useState } from "react";
import { useEffect } from "react";
import { showMessage } from "../../../components/reuseable/Tostify";
import LikeButton from "../../DashboardCmp/Likebutton";
import { getCookies } from "../../../lib/auth";
import { Dialog } from "primereact/dialog";

export const IslamicCulture = () => {
  const { id } = useParams();
  const token = getCookies("token");
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState("");
  const [memberOfOrganization, setMemberOfOrganization] = useState([]);
  const [nonMemberOfOrganization, setNonMemberOfOrganization] = useState([]);
  const [organizationRole, setOrganizationRole] = useState("");
  const [shareModal, setShareModal] = useState(false);
  const dialogRef = useRef(null); // Ref to track the dialog content

  // Effect to handle outside clicks
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        setShareModal(false); // Close modal if click is outside
      }
    };

    // Add event listener when modal is open
    if (shareModal) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener when modal closes or component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [shareModal, setShareModal]);
  const [items, setItems] = useState([]);

  const profileCreation = secureLocalStorage.getItem("profile");
  const navigate = useNavigate();
  useEffect(() => {
    if (profileCreation === "empty") {
      showMessage("Please complete your profile first!", "error");
      navigate("/dashboard");
    }
    if (!token) {
      navigate("/login");
    }
    GetEventsData();
  }, []);
  const GetEventsData = () => {
    setLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(API_Routes.GETISLAMICULTUREPROFILEDATA + id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === 200) {
          setData(result?.data);
          setOrganizationRole(result?.data?.userId?.role);
          const membersArray = result?.data?.userId?.member?.filter(
            (item) =>
              item?.role && item?.role?.toLowerCase()?.includes("member")
          );
          const nonMembersArray = result?.data?.userId?.member?.filter(
            (item) =>
              !item?.role || !item?.role?.toLowerCase()?.includes("member")
          );
          setMemberOfOrganization(membersArray);
          setNonMemberOfOrganization(nonMembersArray);
          setLoader(false);
        } else {
          setLoader(false);
          showMessage(result?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoader(false);
      });
  };
  const role = secureLocalStorage.getItem("role");

  const sendMessage = async () => {
    // alert(data)
    setLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("contactId", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${url}api/${role}/message/create`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status == 201 || result?.status == 200) {
          if (role == "organization") {
            navigate(
              `/organization-dashboard/${8}?id=${result?.data?.contactId}`
            );
          } else {
            navigate(`/student-dashboard/${5}?id=${result?.data?.contactId}`);
          }
        }
        setLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoader(false);
      });
  };

  return (
    <div className="islamicCultureEvent mb-5">
      {loader && (
        <div className="loaderScreen">
          <Loader />
        </div>
      )}
      <DashboardNavbar />
      <div className="mainHeader p-4">
        <div className="row align-items-start g-4">
          <div className="col col-xl-4 col-lg-5 col-md-6 d-flex justify-content-xl-end justify-content-md-center justify-content-md-center">
            <div>
              <img
                className="w-100"
                // src={ data?.image?.url}
                src={img}
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 pt-3 ">
            <div className="d-flex align-items-center islamic-culture justify-content-between">
              <h2>{data?.orgName}</h2>
              <div className="cultureIcons">
                {/* <ReactSVG src={heart} className="point p10" /> */}
                <LikeButton
                  userId={id}
                  initialLiked={data?.isFavorite}
                  // handleLike={(item) => handleLike(item)}
                  runData={true}
                  name="Organization"
                  GetEventsData={GetEventsData}
                  showlarge={false}
                />
                <ReactSVG
                  src={download}
                  className="point p10"
                  onClick={() => setShareModal(true)}
                />
              </div>
            </div>
            {shareModal && (
              <>
                <Dialog
                  visible={shareModal}
                  style={{ width: "32rem" }}
                  breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                  // header="Share"
                  draggable={false}
                  modal
                  onHide={() => setShareModal(false)} // Still handles programmatic closing
                >
                  <div className="share-content p-3" ref={dialogRef}>
                    {" "}
                    {/* Attach ref here */}
                    <div className="d-flex align-items-center justify-content-between gap-3">
                      <h5 className="mb-0 fw-bold">Share</h5>
                      <div
                        className="pointer"
                        onClick={() => setShareModal(false)}
                      >
                        <i
                          className="pi pi-times mr-3"
                          style={{ fontSize: "1rem" }}
                        />
                      </div>
                    </div>
                    <div className="mt-3">
                      <p>Share this Contact:</p>
                      <div className="d-flex align-items-center gap-2 mt-2">
                        <input
                          type="text"
                          value={window.location.href}
                          readOnly
                          className="form-control"
                          style={{ padding: "0.5rem" }}
                        />
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => {
                            const fullUrl = window.location.href;
                            navigator.clipboard.writeText(fullUrl);
                            showMessage("Link copied to clipboard!");
                          }}
                        >
                          <i className="pi pi-copy" />
                        </button>
                      </div>

                      {/* Share to WhatsApp */}
                      <div className="mt-3">
                        <button
                          className="tech-btn tech-btn-2 me-3 w-sm-100 btn btn-success"
                          onClick={() => {
                            const fullUrl = window.location.href;
                            const message = `Check out this contact: ${fullUrl}`;
                            const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
                              message
                            )}`;
                            window.open(whatsappUrl, "_blank");
                          }}
                        >
                          <i className="pi pi-whatsapp pt-1" />
                          Share via WhatsApp
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog>
              </>
            )}
            <div className="cultureLi">
              <p>
                <span>
                  <BsPerson />
                </span>
                <span className="ms-3">
                  Organized by{" "}
                  {nonMemberOfOrganization &&
                  nonMemberOfOrganization[0]?.firstName
                    ? nonMemberOfOrganization[0]?.firstName
                    : " Admin"}
                  {nonMemberOfOrganization?.length &&
                  nonMemberOfOrganization?.length > 2
                    ? " and " +
                      (nonMemberOfOrganization?.length - 1) +
                      " others"
                    : ""}
                  {nonMemberOfOrganization?.length &&
                  nonMemberOfOrganization?.length === 2
                    ? " and " + (nonMemberOfOrganization?.length - 1) + " other"
                    : ""}
                </span>
              </p>
              <p>
                <span>
                  <HiOutlineUsers />
                </span>
                <span className="ms-3">
                  {memberOfOrganization?.length
                    ? memberOfOrganization?.length
                    : 0}{" "}
                  members
                </span>
              </p>
              <p>
                <span>
                  <HiOutlineLocationMarker />
                </span>
                <span className="ms-3">{data?.address}</span>
              </p>
              <p>
                <span>
                  <FiLink2 />
                </span>
                <span className="ms-3">{data?.website}</span>
              </p>

              {organizationRole === "admin" || organizationRole === "Admin" ? (
                <Link to={`/contact`}>
                  <Button
                    class={"tech-btn tech-btn-2 me-3 "}
                    data={"Contact us"}
                  />
                </Link>
              ) : (
                <div onClick={sendMessage}>
                  <Button
                    class={"tech-btn tech-btn-2 me-3 "}
                    data={"Contact us"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="navTabEventSection ">
        <NavsTabs data={data} id={id} />
      </div>
    </div>
  );
};
